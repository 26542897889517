import {
  EmbeddedScene,
  SceneControlsSpacer,
  SceneDataLayerSet,
  SceneGridLayout,
  SceneGridRow,
  SceneRefreshPicker,
  SceneTimePicker,
  SceneTimeRange,
  SceneVariableSet,
  VariableValueSelectors,
} from "@grafana/scenes";
import getEcsRuntimeTaskCountPanels from "./components/Panels/cloudwatch/ecs/task-count";
import getEcsRuntimeCpuUsagePanels from "./components/Panels/cloudwatch/ecs/cpu";
import getRdsRuntimeCpuUsagePanels from "./components/Panels/cloudwatch/rds";
import getSentryPanels from "./components/Panels/sentry";
import getElbStatusCodePanels from "./components/Panels/cloudwatch/elb/status-codes";
import getElbResponseTimePanels from "./components/Panels/cloudwatch/elb/response-times";
import { getAnnotationsDataLayers } from "./components/Annotations/pingdom";
import { Project } from "./metadata/projects";
import { Datasources } from "./datasources";

export const getProjectScene = (project: Project, datasources: Datasources) => {
  const timeRange = new SceneTimeRange({
    from: "now-6h",
    to: "now",
  });

  const loadBalancerNames =
    (project.loadBalancers || []).map(({ arn }) =>
      arn.substring(arn.indexOf("/") + 1),
    ) || [];

  const rdsInstanceNames =
    (project.dbInstances || []).map(({ arn }) => arn.split(":").pop()!) || [];

  const clusterNamesWithServices = (
    project.ecsClusters?.map(({ arn }) =>
      arn.substring(arn.indexOf("/") + 1),
    ) || []
  ).map(
    (clusterName) => ({
      clusterName,
      ecsServices: (project.ecsServices || [])
        .filter(({ arn }) => arn.split("/").includes(clusterName))
        .map(({ arn }) => arn.split("/").pop()!),
    }),
    [],
  );

  let y = 0;
  const sceneChildren: SceneGridRow[] = [];
  for (const [title, panelsFn] of [
    [
      "Sentry",
      () => getSentryPanels(datasources.Sentry, project["Sentry project IDs"]),
    ],
    [
      "ECS Runtimes",
      (y: number) =>
        getEcsRuntimeTaskCountPanels(
          datasources.CloudWatch,
          clusterNamesWithServices,
          y,
        ).concat(
          getEcsRuntimeCpuUsagePanels(
            datasources.CloudWatch,
            clusterNamesWithServices,
            y,
          ),
        ),
    ],
    [
      "RDS Runtimes",
      (y: number) =>
        getRdsRuntimeCpuUsagePanels(
          datasources.CloudWatch,
          rdsInstanceNames,
          y,
        ),
    ],
    [
      "Load balancers",
      (y: number) =>
        getElbStatusCodePanels(
          datasources.CloudWatch,
          loadBalancerNames,
          y,
        ).concat(
          getElbResponseTimePanels(
            datasources.CloudWatch,
            loadBalancerNames,
            y + 12,
          ),
        ),
    ],
  ] as const) {
    const childrenPanels = panelsFn(y);
    if (childrenPanels.length) {
      sceneChildren.push(
        new SceneGridRow({
          title,
          isCollapsible: false,
          width: 24,
          height: 12,
          x: 0,
          y,
          children: childrenPanels,
        }),
      );
      y += 12;
    }
  }

  return new EmbeddedScene({
    $timeRange: timeRange,
    $variables: new SceneVariableSet({ variables: [] }),
    body: new SceneGridLayout({
      $data: new SceneDataLayerSet({
        layers: getAnnotationsDataLayers(datasources.Pingdom, project),
      }),
      children: sceneChildren,
    }),
    controls: [
      new VariableValueSelectors({}),
      new SceneControlsSpacer(),
      new SceneTimePicker({ isOnCanvas: true }),
      new SceneRefreshPicker({
        intervals: ["5s", "1m", "1h"],
        isOnCanvas: true,
      }),
    ],
  });
};
