import {
  PanelBuilders,
  SceneDataProvider,
  SceneDataState,
  SceneGridItem,
} from "@grafana/scenes";
import { Project } from "../../metadata/projects";
import { FieldColorModeId, GraphDrawStyle } from "@grafana/schema";
import { Datasources } from "../../datasources";
import { createPanelGroup } from "./PanelGroup";

export default (
  datasource: Datasources["Sentry"],
  projectIds: Project["Sentry project IDs"],
) => {
  const getQueriesForFilter = () =>
    Object.entries(projectIds || {}).map(([name, id]) => ({
      datasource: datasource,
      environments: [],
      projectIds: [String(id)],
      queryType: "issues",
      refId: name,
    }));

  const transformations = [
    {
      id: "joinByField",
      options: {
        byField: "LastSeen",
        mode: "outer",
      },
    },
    {
      id: "filterFieldsByName",
      options: {
        include: {
          pattern: "UserCount|LastSeen",
        },
      },
    },
    {
      id: "organize",
      options: {
        excludeByName: {
          LastSeen: false,
        },
        indexByName: {},
        renameByName: {
          LastSeen: "",
          UserCount: Object.keys(projectIds!)[0],
          ...Object.keys(projectIds || {}).map((name) => ({
            [`UserCount Issues (${name})`]: name,
          })),
        },
        includeByName: {},
      },
    },
  ];

  const getPanels = (data: SceneDataProvider<SceneDataState>[]) => [
    new SceneGridItem({
      x: 0,
      y: 0,
      width: 24,
      height: 12,
      $data: data[0],
      body: PanelBuilders.timeseries()
        .setTitle("Sentry issues")
        .setCustomFieldConfig("drawStyle", GraphDrawStyle.Bars)
        .setColor({
          mode: FieldColorModeId.Fixed,
          fixedColor: "red",
        })
        .build(),
    }),
  ];

  return createPanelGroup({
    datasource,
    queryFilters: [{ filter: "none" }],
    getQueriesForFilter,
    getPanels,
    transformations,
  });
};
