import {
  SceneDataProvider,
  SceneDataQuery,
  SceneDataTransformer,
  SceneDataTransformerState,
  SceneGridItem,
  SceneQueryRunner,
} from "@grafana/scenes";
import { DataSource } from "../../datasources";

export const getGridCoordinates = (
  idx: number,
  total: number,
  height: number,
) => ({
  x: idx * Math.round(24 / total),
  y: 0,
  width: Math.min(
    12,
    idx === total - 1 ? Math.floor(24 / total) : Math.round(24 / total),
  ),
  height,
});

export const createPanelGroup = <QueryFilter extends object | undefined>({
  datasource,
  queryFilters,
  getQueriesForFilter,
  getPanels,
  transformations,
}: {
  datasource: DataSource;
  queryFilters: QueryFilter[];
  getPanels: (data: SceneDataProvider[]) => SceneGridItem[];
  getQueriesForFilter: (filter: QueryFilter) => SceneDataQuery[];
  transformations?: SceneDataTransformerState["transformations"];
}) => {
  const queryRunners = (queryFilters || [{}]).map(
    (filter) =>
      new SceneQueryRunner({
        datasource: datasource,
        queries: getQueriesForFilter(filter as QueryFilter),
        maxDataPoints: 100,
      }),
  );

  let transformedRunners: SceneDataTransformer[] | undefined;
  if (transformations?.length) {
    transformedRunners = queryRunners.map(
      (queryRunner) =>
        new SceneDataTransformer({
          $data: queryRunner,
          transformations,
        }),
    );
  }

  return getPanels(transformedRunners || queryRunners);
};
