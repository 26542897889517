import {
  FieldConfigOverridesBuilder,
  PanelBuilders,
  SceneDataProvider,
  SceneDataState,
  SceneGridItem,
} from "@grafana/scenes";
import { FieldColorModeId, FieldConfig } from "@grafana/data";
import { GraphDrawStyle } from "@grafana/schema";
import { Datasources } from "../../../../datasources";
import { createPanelGroup, getGridCoordinates } from "../../PanelGroup";

export default (
  datasource: Datasources["CloudWatch"],
  clusterNamesWithServices: {
    clusterName: string;
    ecsServices: string[];
  }[],
  y: number,
) => {
  const getQueriesForFilter = ({ clusterName }: { clusterName: string }) =>
    Object.entries(metrics).map(([metricName, { refId }]) => ({
      datasource,
      datasourceId: 1,
      dimensions: {
        ClusterName: [clusterName],
      },
      expression: "",
      id: "",
      label: "",
      logGroups: [],
      matchExact: false,
      metricEditorMode: 0,
      metricName,
      metricQueryType: 0,
      namespace: "ECS/ContainerInsights",
      period: "",
      queryMode: "Metrics",
      refId,
      region: "eu-north-1",
      sqlExpression: "",
      statistic: "Average",
      type: "timeSeriesQuery",
    }));

  const getPanels = (data: SceneDataProvider<SceneDataState>[]) =>
    data.map(
      (panelData, idx) =>
        new SceneGridItem({
          ...getGridCoordinates(idx, data.length, 12),
          y,
          $data: panelData,
          body: PanelBuilders.timeseries()
            .setTitle(
              `Runtime service CPU usage (${clusterNamesWithServices[idx].clusterName})`,
            )
            .setCustomFieldConfig("drawStyle", GraphDrawStyle.Line)
            .setUnit("mCPU")
            .setDecimals(0)
            .setMin(0)
            .setOverrides((b) =>
              clusterNamesWithServices[idx].ecsServices.reduce(
                (b, serviceName, serviceIdx) =>
                  getMetricColorOverrides(
                    b,
                    serviceName,
                    `rgb(${getColorBetween([0, 0, 255], [255, 0, 255], clusterNamesWithServices[idx].ecsServices.length, serviceIdx).join(",")}`,
                  ),
                b,
              ),
            )
            .build(),
        }),
    );

  const queryFilters = clusterNamesWithServices.map(({ clusterName }) => ({
    clusterName,
  }));
  return createPanelGroup({
    datasource,
    queryFilters,
    transformations: [
      {
        id: "filterFieldsByName",
        options: {
          include: {
            pattern: ".+--.+|Time", // Remove duplicate fields that have the same data, keeping only metric names that include "--" (like runtime--master) and the Time field
          },
          byVariable: false,
        },
      },
    ],
    getQueriesForFilter,
    getPanels,
  });
};

type Rgb = [number, number, number];
const getColorBetween = (
  minColor: Rgb,
  maxColor: Rgb,
  amountOfColors: number,
  colorIndex: number,
): Rgb =>
  amountOfColors === colorIndex + 1
    ? maxColor
    : (minColor.map((min, i) =>
        Math.round(min + ((maxColor[i] - min) * colorIndex) / amountOfColors),
      ) as Rgb);

const metrics = {
  CpuUtilized: { refId: "cpuUtilized" },
  CpuReserved: { refId: "cpuReserved" },
};

const getMetricColorOverrides = (
  b: FieldConfigOverridesBuilder<FieldConfig>,
  serviceName: string,
  color: string,
) =>
  b
    .matchFieldsWithNameByRegex(`${serviceName}.+CpuUtilized`)
    .overrideColor({
      mode: FieldColorModeId.Fixed,
      fixedColor: color,
    })
    .matchFieldsWithNameByRegex(`${serviceName}.+CpuReserved`)
    .overrideColor({
      mode: FieldColorModeId.Fixed,
      fixedColor: color,
    })
    // @ts-expect-error Outdated types
    .overrideCustomFieldConfig("lineStyle", {
      dash: [10, 10],
      fill: "dash",
    });
