import {
  PanelBuilders,
  SceneDataProvider,
  SceneDataState,
  SceneGridItem,
} from "@grafana/scenes";
import { GraphDrawStyle } from "@grafana/schema";
import { Datasources } from "../../../datasources";
import { createPanelGroup, getGridCoordinates } from "../PanelGroup";

export default (
  datasource: Datasources["CloudWatch"],
  instanceNames: string[],
  y: number,
) => {
  const getQueriesForFilter = ({ instanceName }: { instanceName: string }) =>
    Object.entries(metrics).map(([metricName, { refId }]) => ({
      datasource,
      datasourceId: 1,
      dimensions: {
        DBInstanceIdentifier: [instanceName],
      },
      expression: "",
      id: "",
      label: "",
      logGroups: [],
      matchExact: false,
      metricEditorMode: 0,
      metricName,
      metricQueryType: 0,
      namespace: "AWS/RDS",
      period: "",
      queryMode: "Metrics",
      refId,
      region: "eu-north-1",
      sqlExpression: "",
      statistic: "Average",
      type: "timeSeriesQuery",
    }));

  const getPanels = (data: SceneDataProvider<SceneDataState>[]) =>
    data.map(
      (panelData, idx) =>
        new SceneGridItem({
          ...getGridCoordinates(idx, data.length, 12),
          y,
          $data: panelData,
          body: PanelBuilders.timeseries()
            .setTitle(`Instance CPU usage (${instanceNames[idx]})`)
            .setCustomFieldConfig("drawStyle", GraphDrawStyle.Line)
            .setDecimals(0)
            .setMin(0)
            .setMax(0)
            .build(),
        }),
    );

  const queryFilters = instanceNames.map((instanceName) => ({
    instanceName,
  }));
  return createPanelGroup({
    datasource,
    queryFilters,
    getQueriesForFilter,
    getPanels,
  });
};

const metrics = {
  CPUUtilization: { refId: "cpuUtilization" },
};
