import {
  PanelBuilders,
  SceneDataProvider,
  SceneDataQuery,
  SceneDataState,
  SceneGridItem,
} from "@grafana/scenes";
import { Datasources } from "../../../../datasources";
import { createPanelGroup, getGridCoordinates } from "../../PanelGroup";

const metrics = {
  TargetResponseTime: { refId: "targetResponseTime" },
};

export default (
  datasource: Datasources["CloudWatch"],
  loadBalancerNames: string[],
  y: number,
) => {
  const getQueriesForFilter = ({
    loadBalancerName,
  }: {
    loadBalancerName: string;
  }): SceneDataQuery[] =>
    Object.entries(metrics).map(([metricName, { refId }]) => ({
      datasource,
      dimensions: {
        LoadBalancer: loadBalancerName,
      },
      expression: "",
      hide: false,
      id: "",
      label: "",
      logGroups: [],
      matchExact: true,
      metricEditorMode: 0,
      metricName,
      metricQueryType: 0,
      namespace: "AWS/ApplicationELB",
      period: "",
      queryMode: "Metrics",
      refId,
      region: "eu-north-1",
      sqlExpression: "",
      statistic: "p95",
    }));

  const getPanels = (data: SceneDataProvider<SceneDataState>[]) =>
    data.map(
      (panelData, idx) =>
        new SceneGridItem({
          ...getGridCoordinates(idx, data.length, 12),
          y,
          $data: panelData,
          body: PanelBuilders.timeseries()
            .setTitle(`Response times (p95) (${loadBalancerNames![idx]})`)

            .build(),
        }),
    );

  const queryFilters = loadBalancerNames.map((loadBalancerName) => ({
    loadBalancerName,
  }));
  return createPanelGroup({
    datasource,
    queryFilters,
    getQueriesForFilter,
    getPanels,
  });
};
