import {
  PanelBuilders,
  SceneDataProvider,
  SceneDataState,
  SceneGridItem,
} from "@grafana/scenes";
import { Datasources } from "../../../../datasources";
import { createPanelGroup, getGridCoordinates } from "../../PanelGroup";
import { BigValueJustifyMode } from "@grafana/schema";

const metrics = {
  RunningTaskCount: { refId: "runningTaskCount" },
};

export default (
  datasource: Datasources["CloudWatch"],
  clusterNamesWithServices: {
    clusterName: string;
    ecsServices: string[];
  }[],
  y: number,
) => {
  const getQueriesForFilter = ({ clusterName }: { clusterName: string }) =>
    Object.entries(metrics).map(([metricName, { refId }]) => ({
      datasource,
      datasourceId: 1,
      dimensions: {
        ClusterName: [clusterName],
      },
      expression: "",
      id: "",
      label: "",
      logGroups: [],
      matchExact: false,
      metricEditorMode: 0,
      metricName,
      metricQueryType: 0,
      namespace: "ECS/ContainerInsights",
      period: "",
      queryMode: "Metrics",
      refId,
      region: "eu-north-1",
      sqlExpression: "",
      statistic: "Average",
      type: "timeSeriesQuery",
    }));

  const getTaskCountPanels = (data: SceneDataProvider<SceneDataState>[]) =>
    data.map(
      (panelData, idx) =>
        new SceneGridItem({
          ...getGridCoordinates(idx, data.length, 6),
          y,
          $data: panelData,
          body: PanelBuilders.stat()
            .setTitle(
              `Running tasks (${clusterNamesWithServices[idx].clusterName})`,
            )
            .setMin(0)
            .setOption("justifyMode", BigValueJustifyMode.Center)
            .setDecimals(0)
            .build(),
        }),
    );

  const queryFilters = clusterNamesWithServices.map(({ clusterName }) => ({
    clusterName,
  }));
  return createPanelGroup({
    datasource,
    queryFilters,
    getQueriesForFilter,
    getPanels: getTaskCountPanels,
  });
};
